// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

//api: 'http://www.hmg-ipess.saude.df.gov.br/sigih/rest/api',
export const environment = {
  production: false,
  api: 'https://www.ipess.saude.df.gov.br/sigih/rest/api',
  auth: 'https://www.ipess.saude.df.gov.br/sigih/auth',
  // api: 'http://localhost:8082/sigih/rest/api',
  // auth: 'http://localhost:8081/sigih/auth',
  microServiceFishbone: '/fishbone/',
  clientId: 'sigihweb',
  basicAuthorization: 'Basic c2lnaWh3ZWI6c2VjcmV0',
  csvIntervalMin: 1,
  versaoEcossistema: '3.7',
  versaoAppWeb: '1.8.5'
};

/**
 export const environment = {
 production: false,
 api: 'http://localhost:8082/sigih/rest/api',
 auth: 'http://localhost:8081/sigih/auth',
 microServiceFishbone: '/fishbone/',
 clientId: 'sigihweb',
 basicAuthorization: 'Basic c2lnaWh3ZWI6c2VjcmV0',
 csvIntervalMin: 1
 };

 */

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
